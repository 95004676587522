import { ethers } from "ethers";

export const formatToNDecimals = (num, n) => {
  // Convierte el argumento a un número y luego a un string con n decimales
  const formattedNumber = Number(num).toFixed(n);
  // Convierte el string formateado nuevamente a un número para eliminar ceros no significativos
  return Number(formattedNumber);
};

export const formatToSolidity = (num, decimals) => {
  const formattedNumber = ethers.utils.parseUnits(num.toString(), decimals);
  return formattedNumber;
};

export const roundToNDecimals = (num, n) => {
  const number = Number(num);
  return Math.round(number * 10 ** n) / 10 ** n;
};

export const cleanInput = (num) => {
  // Usar una expresión regular para encontrar ceros a la izquierda y removerlos
  // La expresión ^0+ encuentra uno o más ceros al inicio del string
  let valorStr = num.toString().replace(/^0+/, "");
  // Puedes descomentar la siguiente línea si necesitas el resultado como número
  let number = Number(valorStr);

  return formatToNDecimals(number, 6);
};

export const formatDisplayNumber = (num, decimals) => {
  const number = Number(num);
  // Verificar si el valor convertido es un número válido
  if (!isNaN(number)) {
    return number.toFixed(decimals);
  } else {
    // Manejar el caso en que la entrada no pueda ser convertida a un número
    return "";
  }
};
