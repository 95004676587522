import { useEffect, useState } from "react";
import "./App.css";
import { ethers } from "ethers";
import faucetContract from "./ethereum/faucet";
import { formatDisplayNumber } from "./utilityFunctions";
import usdtIcon from "./Usdt-Icon.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function App() {
  const [walletAddress, setWalletAddress] = useState("");
  const [signer, setSigner] = useState();
  const [fcContract, setFcContract] = useState();
  const [gasBalance, setGasBalance] = useState("");
  const [daUsdtBalance, setDausdtBalance] = useState("");
  const [daUsdtTotalSupply, setDausdtTotalSupply] = useState("");
  const [dispenseAmount, setDispenseAmount] = useState("");

  useEffect(() => {
    if (walletAddress) {
      const intervalId = setInterval(() => {
        updateBalances();
      }, 1000);

      return () => {
        clearInterval(intervalId);
        setGasBalance("");
        setDausdtBalance("");
        setDausdtTotalSupply("");
      };
    }
  }, [walletAddress]);

  useEffect(() => {
    const init = async () => {
      await getCurrentWalletConnected();
      await addWalletListener();
    };

    init();
  }, []);

  const getConnectAction = async () => {
    await changeNetwork();
    await connectWallet();
  };

  const updateBalances = async () => {
    try {
      if (!signer || !fcContract) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        setSigner(provider.getSigner());
        setFcContract(faucetContract(provider));
        return;
      }

      // console.log(signer);
      // console.log(fcContract);

      /* Obtener el saldo del wallet actual en ETH */
      const ethBalance = await signer.getBalance();
      const formattedEthBalance = ethers.utils.formatUnits(ethBalance, 18);
      setGasBalance(formattedEthBalance);

      /* Obtener el balance de DAUSDT y totalSupply */
      const fcContractWithSigner = fcContract.connect(signer);

      const daUsdtBalance = await fcContractWithSigner.balanceOf(walletAddress);

      const formattedDausdtBalance = ethers.utils.formatUnits(daUsdtBalance, 6);

      const daUsdtTotalSupply = await fcContractWithSigner.totalSupply();

      const formattedDausdtTotalSupply = ethers.utils.formatUnits(
        daUsdtTotalSupply,
        6
      );

      const dispenseAmountNow = await fcContractWithSigner.dispenseAmount();

      const formattedDispenseAmountNow = ethers.utils.formatUnits(
        dispenseAmountNow,
        6
      );

      setDausdtBalance(formattedDausdtBalance);
      setDausdtTotalSupply(formattedDausdtTotalSupply);
      setDispenseAmount(formattedDispenseAmountNow);
      console.log("EL DISPENSE AMOUNT ES: ", dispenseAmount);
    } catch (err) {
      console.error(err.message);
    }
  };

  const connectWallet = async () => {
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      try {
        /* get provider */
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        /* get accounts */
        const accounts = await provider.send("eth_requestAccounts", []);
        /* get signer */
        const signer = provider.getSigner();
        setSigner(signer);
        /* local contract instance */
        const faucet = faucetContract(provider);
        setFcContract(faucet);
        /* set active wallet address */
        setWalletAddress(accounts[0]);
      } catch (err) {
        console.error(err.message);
      }
    } else {
      /* MetaMask is not installed */
      console.log("Please install MetaMask");
      window.open("https://metamask.io/download/", "_blank");
    }
  };

  const getCurrentWalletConnected = async () => {
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      try {
        /* get provider */
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        /* get accounts */
        const accounts = await provider.send("eth_accounts", []);
        if (accounts.length > 0) {
          /* get signer */
          setSigner(provider.getSigner());
          /* local contract instance */
          setFcContract(faucetContract(provider));
          /* set active wallet address */
          setWalletAddress(accounts[0]);

          if (signer) {
            await updateBalances();
          }
        } else {
          console.log("Connect to MetaMask using the Connect Wallet button");
        }
      } catch (err) {
        console.error(err.message);
      }
    } else {
      /* MetaMask is not installed */
      console.log("Please install MetaMask");
    }
  };

  const addWalletListener = async () => {
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      window.ethereum.on("accountsChanged", async (accounts) => {
        setWalletAddress(accounts[0]);
        await updateBalances();
      });
    } else {
      /* MetaMask is not installed */
      setWalletAddress("");
      console.log("Please install MetaMask");
    }
  };

  const getDAUSDTHandler = async () => {
    try {
      const fcContractWithSigner = fcContract.connect(signer);
      showIndications();
      const resp = await fcContractWithSigner.dispense();
      if (resp.hash) {
        Swal.close();
        showLoadingModal();
      }
      await resp.wait();
      Swal.close();
      showSuccessModal(resp.hash);
    } catch (err) {
      const reasonMatch = err.message.match(/execution reverted: (.+?)",/);
      const reason = reasonMatch
        ? capitalizeFirstLetter(reasonMatch[1])
        : "An error occurred while processing your transaction. Please try again later.";

      showErrorModal("Transaction Failed", reason);
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return string; // Asegura que haya una cadena para trabajar
    return string.charAt(0).toUpperCase() + string.slice(1); // Capitaliza la primera letra
  };

  const importTokenToMetaMask = async () => {
    try {
      if (window.ethereum) {
        const wasAdded = await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: "0xB6020E4f0D3712650a5F3E7102c66C0FC586B135", // Dirección del contrato del token
              symbol: "DAUSDT", // Símbolo del token
              decimals: 6, // Decimales
              image: usdtIcon, // URL de la imagen del token
            },
          },
        });

        if (wasAdded) {
          console.log("Token agregado a MetaMask.");
        } else {
          console.log("El usuario no agregó el token.");
        }
      } else {
        /* MetaMask is not installed */
        console.log("Please install MetaMask");
        window.open("https://metamask.io/download/", "_blank");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const changeNetwork = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }], // Polygon Mainnet chainId en formato hexadecimal
        });
        return true;
      } catch (error) {
        return false;
      }
    }
  };

  const logout = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_revokePermissions",
          params: [
            {
              eth_accounts: {},
            },
          ],
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  const MySwal = withReactContent(Swal);

  const swalCustomStyles = {
    popup: {
      background: "#150257",
      color: "white",
    },
    confirmButton: "boton-colorido", // Clase personalizada para el botón
    title: "title",
  };

  const showIndications = () => {
    MySwal.fire({
      title: "Waiting for your confirmation",
      text: "Confirm the transaction on the right side of the screen",
      didOpen: () => {
        Swal.showLoading();
      },
      background: swalCustomStyles.popup.background,
      color: swalCustomStyles.popup.color,
      customClass: {
        confirmButton: swalCustomStyles.confirmButton,
        title: swalCustomStyles.title,
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
  };

  const showLoadingModal = () => {
    MySwal.fire({
      title: "Processing, please wait...",
      didOpen: () => {
        Swal.showLoading();
      },
      background: swalCustomStyles.popup.background,
      color: swalCustomStyles.popup.color,
      customClass: {
        confirmButton: swalCustomStyles.confirmButton,
        title: swalCustomStyles.title,
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
  };

  const showSuccessModal = (transactionHash) => {
    MySwal.fire({
      icon: "success",
      title: "¡Congratulations!",
      text: "You successfully received your Test USDT.",
      html: `
      <p class="responsive-text" style="margin-bottom: 20px;">You successfully received your Test USDT.</p>
      <button id="get-token-btn" class="boton-colorido" style="display: inline-block; margin-right: 8px;">Import Token</button>
      <button id="see-transaction-btn" class="boton-colorido" style="display: inline-block; margin-right: 8px;">See Transaction</button>
      <button id="close-btn" class="boton-colorido" style="display: inline-block;">Close</button>
      `,
      showConfirmButton: false,
      focusConfirm: false,
      didOpen: () => {
        // Aplica aquí cualquier estilo adicional si es necesario
        const getBtn = document.getElementById("get-token-btn");
        const closeBtn = document.getElementById("close-btn");
        const seeTransactionBtn = document.getElementById(
          "see-transaction-btn"
        );
        const btnStyle = {
          background:
            "linear-gradient(270deg, #b0f65b, #01c6c5 22.97%, #4a7ff3 51.93%, #8171f5 83.85%, #b66df3)",
          borderRadius: "100px",
          fontStyle: "normal",
          fontWeight: "700",
          textAlign: "center",
          border: "none",
          color: "white", // Asegúrate de que el texto sea visible
          padding: "10px 24px", // Ajusta al tamaño deseado
          cursor: "pointer",
        };
        Object.assign(getBtn.style, btnStyle);
        Object.assign(closeBtn.style, btnStyle);
        Object.assign(seeTransactionBtn.style, btnStyle);

        getBtn.addEventListener("click", async () => {
          await importTokenToMetaMask();
        });

        closeBtn.addEventListener("click", () => {
          Swal.close();
        });

        seeTransactionBtn.addEventListener("click", () => {
          window.open(
            `https://polygonscan.com/tx/${transactionHash}`,
            "_blank"
          );
        });
      },
      background: "#150257",
      color: "white",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
  };

  const showErrorModal = (title, errorMessage) => {
    MySwal.fire({
      icon: "error",
      title: title,
      html: `
        <p class="responsive-text" style="margin-bottom: 20px;">${errorMessage}</p>
        <button id="close-error-btn" class="boton-colorido" style="display: inline-block;">Close</button>
      `,
      showConfirmButton: false, // Oculta el botón de confirmación predeterminado
      focusConfirm: false,
      didOpen: () => {
        // Aplica aquí cualquier estilo adicional si es necesario
        const closeBtn = document.getElementById("close-error-btn");
        const btnStyle = {
          background:
            "linear-gradient(270deg, #b0f65b, #01c6c5 22.97%, #4a7ff3 51.93%, #8171f5 83.85%, #b66df3)",
          borderRadius: "100px",
          fontStyle: "normal",
          fontWeight: "700",
          textAlign: "center",
          border: "none",
          color: "white", // Asegúrate de que el texto sea visible
          padding: "10px 24px", // Ajusta al tamaño deseado
          cursor: "pointer",
        };
        Object.assign(closeBtn.style, btnStyle);

        closeBtn.addEventListener("click", () => {
          Swal.close();
        });
      },
      background: swalCustomStyles.popup.background,
      color: swalCustomStyles.popup.color,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
  };

  return (
    <>
      <nav className="navbar">
        <div className="container">
          <div className="navbar-brand">
            <img
              className="navbar-logo"
              src="https://dappsfactory.io/img/demos/business-consulting-3/logo-footer.png"
              alt="dappsfaucet"
            />
          </div>
          <div id="navbarMenu" className="navbar-menu">
            <div className="navbar-end is-align-items-center">
              <button
                className="button boton-colorido connect-wallet mr-3"
                onClick={importTokenToMetaMask}
              >
                <span className="has-text-white has-text-weight-bold">
                  Import Token
                </span>
              </button>
              <button
                className="button boton-colorido connect-wallet mr-3"
                disabled={walletAddress && walletAddress.length > 0}
                onClick={getConnectAction}
              >
                <span className="has-text-white has-text-weight-bold">
                  {walletAddress && walletAddress.length > 0
                    ? `Connected: ${walletAddress.substring(
                        0,
                        6
                      )}...${walletAddress.substring(38)}`
                    : "Connect Wallet"}
                </span>
              </button>
              {walletAddress && walletAddress.length > 0 && (
                <button
                  className="button boton-colorido connect-wallet"
                  disabled={!walletAddress || walletAddress.length <= 0}
                  onClick={logout}
                >
                  <span className="has-text-white has-text-weight-bold">
                    Logout
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </nav>
      <section className="hero is-fullheight">
        <div className="faucet-hero-body">
          <div className="container has-text-centered main-content">
            <h1 className="title is-1">DAppsFaucet</h1>
            <p>Fast and reliable. Get {dispenseAmount} Test USDT Now!</p>
            <div className="columns mt-2">
              <div className="column">
                <div>
                  <img
                    className="logo-faucet"
                    src="ethereum.png"
                    alt="dappsfaucet"
                  />
                  <h2 className="title text-colorido">
                    {gasBalance !== ""
                      ? formatDisplayNumber(gasBalance, 4)
                      : "Connect Wallet Please..."}
                  </h2>
                  <p className="subtitle has-text-white">Gas Balance</p>
                </div>
              </div>
              <div className="column">
                <div>
                  <img
                    className="logo-faucet"
                    src="usd.png"
                    alt="dappsfaucet"
                  />
                  <h2 className="title text-colorido">
                    {daUsdtBalance !== ""
                      ? formatDisplayNumber(daUsdtBalance, 2)
                      : "Connect Wallet Please..."}
                  </h2>
                  <p className="subtitle has-text-white">USDT Balance</p>
                </div>
              </div>
              <div className="column">
                <div>
                  <img
                    className="logo-faucet"
                    src="coin.png"
                    alt="dappsfaucet"
                  />
                  <h2 className="title text-colorido">
                    {daUsdtTotalSupply !== ""
                      ? formatDisplayNumber(daUsdtTotalSupply, 2)
                      : "Connect Wallet Please..."}
                  </h2>
                  <p className="subtitle has-text-white">Total Supply</p>
                </div>
              </div>
            </div>
            <div className="box address-box">
              <div className="columns">
                <div className="column is-four-fifths">
                  <input
                    className="input is-medium"
                    type="text"
                    placeholder="Wallet address (0x...)"
                    disabled
                    defaultValue={walletAddress}
                  />
                </div>
                <div className="column">
                  <button
                    className="button is-medium boton-colorido has-text-white"
                    onClick={getDAUSDTHandler}
                    disabled={walletAddress ? false : true}
                  >
                    GET DAUSDT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
